import React from 'react';
import './ProjectsIntro.css';

function ProjectsIntro() {
	return (
		<div className='projects-intro'>
			<div className='projects-intro-container'>
				<div className='projects-intro__wrapper'>
					<div className='projects-intro__writing'>
						<h1>Languages</h1>
						<p>
							<br />
							JavaScript, TypeScript, Python, C#, C++,
							MySQL, Golang, HTML, CSS, Bash
							<br /> <br />
						</p>

						<h1>
							<br />
							Frameworks
							<br />
						</h1>
						<p>
							<br /> Xamarin, React.js, Next.js, Node.js,
							Vue.js, Express, Flask, Tailwindcss, and
							ASPX.NET
						</p>

						<h1>
							<br />
							Tools:
							<br />
						</h1>
						<p>
							<br /> Linux, Windows, MacOS, Git/Github,
							VirtualBox, RESTful and SOAP web services, Web
							APIs, Command Line
						</p>

						<h1>
							<br />
							Technical Skills:
							<br />
						</h1>
						<p>
							<br /> Microsoft Office Tools, Technical
							Writing, Technical Communication, Data
							Visualization, Critical Thinking
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ProjectsIntro;
